import React, { useEffect, useContext, useRef } from "react"
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { ContextLoader } from '../context'
import { GlobalEventBus } from '../helpers/eventBus'
import Contact from '../components/Contact'
import SplitText from '../components/SplitText'
import Parallax from '../components/Parallax'
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import ImageLazyLoad from '../components/ImageLazyLoad'
import Seo from "../components/Seo"

const About = ({ data }) => {
	const pageData = data.prismicAbout.data

	/**
 	*	Detect elements on screen
	*/
	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true || localStorage.getItem('is-touch') === 'true') return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

	/**
 	*	Color
	*/
	useEffect(() => {
		GlobalEventBus.emit('navColorChange', { newColor: 'white' })
	}, [])

	/**
 	*	Request animation frame
	*/
	useEffect(() => {

		let rafId = null

		const tick = () => {

			if (navColor.current !== 'black' && window.scrollY > 965) {
				GlobalEventBus.emit('navColorChange', { newColor: 'black' })
			}
			else if (navColor.current !== 'white' && window.scrollY <= 965) {
				GlobalEventBus.emit('navColorChange', { newColor: 'white' })
			}

			rafId = window.requestAnimationFrame(tick)
		}

		tick()

		return () => {
			window.cancelAnimationFrame(rafId)
		}
	}, [])

	/**
	*	Get nav color at the init and on change
	*/
	const navColor = useRef(null)
	useEffect(() => {
		const handleNavColorChange = e => {
			navColor.current = e.detail.newColor
		}
		GlobalEventBus.on('navColorChange', handleNavColorChange)
		return () => {
			GlobalEventBus.off('navColorChange', handleNavColorChange)
		}
	}, [])

  return (
    <main className="About">
			<Seo
				title='About'
				description='Independant Production Company Focused on Delivering Inspiring and Well- Crafted Content Across Advertising, Branded Content, Short Fiction and Documentary'
			/>
			<section className="About__Hero">
				<h1 className="About__HeroTitle" data-is-on-screen>
					{pageData.titre.text}
				</h1>
				<div className="About__HeroText" data-is-on-screen>
					<SplitText hasMask>
						<RichText render={pageData.hero_text.richText}/>
					</SplitText>
				</div>
				<div className="About__HeroOverlay"></div>
				<Parallax
					className="About__HeroBackgroundParallax"
					scaleTarget={0.2}
					translateTarget={0}
					isOnMobile={false}
				>
					<ImageLazyLoad
						className="About__HeroBackground"
						alt={pageData.hero_background_image.alt}
						src={pageData.hero_background_image.fluid.src}
						srcSet={pageData.hero_background_image.fluid.srcSet}
					/>
				</Parallax>
				<Parallax
					className="About__HeroSmallImageParallax"
					translateTarget={20}
					isOnMobile={false}
				>
					<img
						className="About__HeroSmallImage"
						data-is-on-screen
						alt={pageData.hero_small_image.alt}
						src={pageData.hero_small_image.fluid.src}
						srcSet={pageData.hero_small_image.fluid.srcSet}
					/>
				</Parallax>
			</section>
			<section className="About__SecondSection">
				<h2 className="About__SecondSectionTitle About__SectionsTitles" data-is-on-screen>
					<SplitText hasMask>
						<RichText render={pageData.second_section_title.richText}/>
					</SplitText>
				</h2>
				<div className="About__SecondSectionText About__SectionsTexts" data-is-on-screen>
					<RichText render={pageData.second_section_text.richText}/>
				</div>
				<div className="About__SecondSectionImages">
					<Parallax
						className="About__SecondSectionImageParallax"
						translateTarget={20}
						isOnMobile={false}
					>
						<img
							className="About__SecondSectionImage"
							data-is-on-screen
							alt={pageData.second_section_image_left.alt}
							src={pageData.second_section_image_left.fluid.src}
							srcSet={pageData.second_section_image_left.fluid.srcSet}
						/>
					</Parallax>
					<Parallax
						className="About__SecondSectionImageParallax"
						translateTarget={0}
						isOnMobile={false}
					>
						<img
							className="About__SecondSectionImage"
							data-is-on-screen
							alt={pageData.second_section_image_middle.alt}
							src={pageData.second_section_image_middle.fluid.src}
							srcSet={pageData.second_section_image_middle.fluid.srcSet}
						/>
					</Parallax>
					<Parallax
						className="About__SecondSectionImageParallax"
						translateTarget={40}
						isOnMobile={false}
					>
						<img
							className="About__SecondSectionImage"
							data-is-on-screen
							alt={pageData.second_section_image_right.alt}
							src={pageData.second_section_image_right.fluid.src}
							srcSet={pageData.second_section_image_right.fluid.srcSet}
						/>
					</Parallax>
				</div>
			</section>
			<section className="About__ThirdSection">
				<h2 className="About__ThirdSectionTitle About__SectionsTitles" data-is-on-screen>
					<SplitText hasMask>
						<RichText render={pageData.third_section_title.richText}/>
					</SplitText>
				</h2>
				<div className="About__ThirdSectionText About__SectionsTexts" data-is-on-screen>
					<RichText render={pageData.third_section_text.richText}/>
				</div>
				<Parallax
					className="About__ThirdSectionImageParallax"
					scaleTarget={0.1}
					isOnMobile={false}
				>
					<img
						className="About__ThirdSectionImage"
						data-is-on-screen
						alt={pageData.third_section_image.alt}
						src={pageData.third_section_image.fluid.src}
						srcSet={pageData.third_section_image.fluid.srcSet}
					/>
				</Parallax>
			</section>
			<section className="About__FourthSection">
				<div className="About__FourthSectionTitle About__SectionsTitles" data-is-on-screen>
					<SplitText hasMask>
						<RichText render={pageData.fourth_section_title.richText}/>
					</SplitText>
				</div>
				<div className="About__FourthSectionText About__SectionsTexts" data-is-on-screen>
					<RichText render={pageData.fourth_section_text.richText}/>
				</div>
				<Parallax
					className="About__FourthSectionImageParallax"
					scaleTarget={0.2}
					translateTarget={0}
					isOnMobile={false}
				>
					<img
						className="About__FourthSectionImage"
						data-is-on-screen
						alt={pageData.fourth_section_image.alt}
						src={pageData.fourth_section_image.fluid.src}
						srcSet={pageData.fourth_section_image.fluid.srcSet}
					/>
				</Parallax>
			</section>
			<Contact data={data.prismicContact.data}/>
    </main>
  )
}

export default About

export const query = graphql`
	query About {
		prismicAbout {
			data {
				titre {
					text
				}
				hero_text {
					richText
				}
				hero_background_image {
					alt
					fluid {
						src
						srcSet
					}
				}
				hero_small_image {
					alt
					fluid {
						src
						srcSet
					}
				}
				second_section_title {
					richText
				}
				second_section_text {
					richText
				}
				second_section_image_left {
					alt
					fluid {
						src
						srcSet
					}
				}
				second_section_image_middle {
					alt
					fluid {
						src
						srcSet
					}
				}
				second_section_image_right {
					alt
					fluid {
						src
						srcSet
					}
				}
				third_section_title {
					richText
				}
				third_section_text {
					richText
				}
				third_section_image {
					alt
					fluid {
						src
						srcSet
					}
				}
				fourth_section_title {
					richText
				}
				fourth_section_text {
					richText
				}
				fourth_section_image {
					alt
					fluid {
						src
						srcSet
					}
				}
			}
		}
		prismicNav {
			...Nav
		}
		prismicContact {
      ...Contact
    }
	}
`